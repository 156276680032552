import React from 'react'
import { Helmet } from 'react-helmet'
function Session() {
    return (
        <>
            <Helmet>
                <meta name="description" content='' />
                <title>ACTET:SpecialSession</title>
                <link rel="canonical" href="https://actet2023.skit.ac.in/actet-2023" />
            </Helmet>
            <main>
                <div className='about'>
                    <div>
                        <p><b>1st International Conference Advanced Computing Techniques in Engineering & Technology (ACTET-2023) was technically sponsored by Soft Computing Research Society (SCRS) was held during December 18-19, 2023 at SKIT Jaipur.</b></p>
                        <p><b><i>Publication: 7 papers have been selected for publication of conference proceedings in Communications in Computer and Information Science (CCIS, volume 2000) from 89 submissions.</i></b></p>
                        <p><b>Link to ACTET-2023 CCIS Proceedings: <a href='https://link.springer.com/book/10.1007/978-3-031-54162-9' target='_blank'>https://link.springer.com/book/10.1007/978-3-031-54162-9</a></b></p>
                        <h2>Editors and Affiliations</h2>
                        <div>
                        <p><b>•	University of Sharjah, Sharjah, United Arab Emirates</b></p>
                        <p><i>Ramesh C. Bansal</i></p>
                        </div>
                        <div>
                        <p><b>•	Reshetnev Siberian State University of Science and Technology Krasnoyarsk, Krasnoyarsk, Russia</b></p>
                        <p><i>Margarita N. Favorskaya</i></p>
                        </div>
                        <div>
                        <p><b>•	Manipal University, Jaipur, India</b></p>
                        <p><i>Shahbaz Ahmed Siddiqui</i></p>
                        </div>
                        <div>
                        <p><b>•	Swami Keshvanand Institute of Technology Management and Gramothan, Jaipur, India</b></p>
                        <p><i>Pooja Jain, Ankush Tandon</i></p>
                        </div>
                    </div>
                </div>
            </main>

        </>

    )
}

export default Session;
