import React from 'react';
import '../app.css'
import { Helmet } from "react-helmet";

function Registration() {

    return (
        <>
            <Helmet>
                <meta name="description" content='' />
                <title>ACTET: Registration</title>
                <link rel="canonical" href="https://actet2023.skit.ac.in/registration" />
            </Helmet>
            <main>
                <div className='about'>
                    <div style={{ marginTop: "3rem" }}>
                        <h2>CONFERENCE REGISTRATION FEE</h2>
                        <table class="pure-table pure-table-horizontal" style={{width:'100%'}}>
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th colSpan={4} style={{textAlign:'center'}}>Mode of Attending Conference</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th colSpan={2} style={{textAlign:'center'}}>Online</th>
                                    <th colSpan={2} style={{textAlign:'center'}}>Physical</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>Non SCRS Member</th>
                                    <th>SCRS Member</th>
                                    <th>Non SCRS Member</th>
                                    <th>SCRS Member</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Students/Research Scholars</td>
                                    <td>₹ 5,500/-</td>
                                    <td>₹ 4400/-</td>
                                    <td>₹ 6,500/-</td>
                                    <td>₹ 5200/-</td>
                                </tr>
                                <tr>
                                    <td>Academicians</td>
                                    <td>₹ 6,500/-</td>
                                    <td>₹ 5200/-</td>
                                    <td>₹ 7,500/-</td>
                                    <td>₹ 6000/-</td>
                                </tr>
                                <tr>
                                    <td>Industry Participants</td>
                                    <td>₹ 7500/-</td>
                                    <td>₹ 6000/-</td>
                                    <td>₹ 8,500/-</td>
                                    <td>₹ 6800/-</td>
                                </tr>
                                <tr>
                                    <td>Non-Authors Participant</td>
                                    <td>₹ 2,000/-</td>
                                    <td>₹ 1600/-</td>
                                    <td>₹ 3,000/-</td>
                                    <td>₹ 2400/-</td>
                                </tr>
                                <tr>
                                    <td>Foreign delegates (All Categories)</td>
                                    <td>$ (USD) 80</td>
                                    <td>$ (USD) 66</td>
                                    <td>$ (USD) 90</td>
                                    <td>$ (USD) 72</td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <p>
                            • At least one of the authors of the paper has to register for the Conference. It is compulsory for one of the author to present the paper in the conference to get the paper to be published in Conference Proceedings.
                        </p>
                        <p>• In order to register in the SCRS member category (subsidized registration fees), you can first become a member at <a href='https://www.scrs.in/register'>https://www.scrs.in/register</a> and then register for the conference.</p>
                        <p>• Registration fee towards the conference includes online conference proceedings, presentation during the conference, conference kit, attendance to all the sessions, and keynote talks. </p>
                        <p><b>Refund and Cancellation Policy</b>: A candidate once registered and paid the registration fee is not allowed to cancel the registration.</p> */}
                    </div>
                </div>
            </main>
        </>
    )

}
export default Registration;