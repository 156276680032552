import Header from 'components/Header'
import NavigationMenu from 'components/NavigationMenu'
import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../app.css'
// import ReaminingTime from 'components/ReaminingTime';
import Footer from 'components/Footer';
import { Route, Routes } from 'react-router-dom';
import AboutUs from './aboutUs';
import Home from './Home';
import Authors from './authors';
import Advisory from './advisoryBoard';
import Tpc from './tpc';
import Committe from './committe';
import ContactUs from './contactUs';
import Registration from './registration';
import Speakers from './speakers';
import Lot from './lot';
import Session from './specialSession';

function App() {

    return (
        <>
        <section>
        <NavigationMenu className = "mobile"/>
        </section>
            <header>
                <Header />
                <NavigationMenu className="desktop"/>
            </header>
            <main>

                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route exact path='/about-us' element={<AboutUs />} />
                    <Route exact path='/cfp' element={<Authors />} />
                    <Route exact path='/advisory' element={<Advisory />} />
                    <Route exact path='/tpc' element={<Tpc />} />
                    <Route exact path='/organizing' element={<Committe />} />
                    <Route exact path='/contact-us' element={<ContactUs />} />
                    <Route exact path='/registration' element={<Registration />} />
                    {/* <Route exact path='/speakers' element={<Speakers />} /> */}
                    <Route exact path='/LOT' element={<Lot />} />
                    <Route exact path='/actet-2023' element={<Session />} />

                </Routes>

            </main>
            <footer>
                <Footer />
            </footer>
        </>
    )
}

export default App;