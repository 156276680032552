import React from 'react'
import { Helmet } from "react-helmet";
function ContactUs() {
  return (
    <div>
      <Helmet>
        <meta name="description" content='' />
        <title>ACTET: Contact US</title>
        <link rel="canonical" href="https://actet2023.skit.ac.in/contact-us" />
      </Helmet>
      <div style={{textAlign:"center"}}>
        <img src='images/icons8-contact-us-68.png'alt='ContactUs'style={{ textAlign: 'center', paddingTop: '80px' }}/>
        {/* <span style={{ textAlign: 'center', paddingTop: '80px',verticalAlign:"top" }}>Contact Us</span> */}
      </div>
      
      <div style={{ textAlign: 'center', padding: '5px 50px 50px 50px', lineHeight: '2rem' }}>
        <div><b>Organizing Team</b></div>
        <div>
          <i className="fa fa-envelope" style={{ fontSize: "36px", color: "#0763ab" }}></i>
          <span style={{paddingLeft:"1rem",verticalAlign:"super"}}><b>Mail</b>: <a href="mailto:actet@skit.ac.in">actet@skit.ac.in</a></span>
        </div>
        <div>
          <img src='images/world-wide-web.png' alt='web' style={{ height: "5vh" }} />
          <span style={{paddingLeft:"1rem",verticalAlign:"super"}}><b>Website</b>:<a href='https://actet.skit.ac.in'>actet.skit.ac.in</a></span></div>
        {/* <div>
        <i className="fa fa-phone" style={{fontSize:'36px',color:'#0763ab'}}></i>
          <span style={{paddingLeft:"1rem",verticalAlign:"super"}}><b>Contact Persons</b></span></div> */}
        {/* <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Ankush Tandon</b> (+91 7737279925),&nbsp;<b>Pooja Jain</b> (+91 9785050506)</div> */}
        {/* <div>Pooja Jain (+91 9785050506)</div> */}
      </div>
    </div>
  )
}

export default ContactUs;
