import React from 'react';
import '../app.css'
import {Helmet} from "react-helmet";
function Advisory() {

    return (
        <>
        <Helmet>
        <meta name="description" content='' />
        <title>ACTET: Advisory</title>
                <link rel="canonical" href="https://actet2023.skit.ac.in/advisory" />
        </Helmet>
            <main>
                <div className='about'>
                    <h2 className='marginTop'>Advisory Board</h2>
                    <p>
                        •  Ameena Al Sumaiti,Khalifa University, UAE<br />
                        •  Hasmat Malik,Universiti Teknologi Malaysia (UTM)| Malaysia.<br />
                        •  Ramkrishan Maheshwari,Centre for Industrial Electronics (CIE), Dep. of Mechanical and Electrical Engineering university of Southern Denmark<br />
                        •  Margarita N Favorskaya,Siberian State University of Science & Technology<br />
                        •  Lakhmi Jain,Adj Professor - University of Technology Sydney<br />
                        •  Richi Nayak,Queensland University of Technology, Brisbane, Australia<br />
                        •  R C Bansal,University of Sharjah,UAE<br />
                        •  Habil Eng LarisA,Faculty of Management,Timisora,Romania<br />
                        •  Prof.Dr.Samaranda Belciug,Department of Computer Science,University of Craiova<br />
                        •  A G Hessami,Vega Systems, London-UK<br />
                        •  Neeraj Gupta,Oakland University<br />
                        •  Nand Kishore Meena,, Aston University, UK<br/>
                        •  Prof.Ikbal Ali,Jamia Millia Islamia, New Delhi<br/>
                        •  Prof.Rizwan,DTU,Delhi<br/>
                        •  Asadur Rahman,NIT,Srinagar<br />
                        •  Kushal Jagtap,National Institute of Technology (NIT), Srinagar<br />
                        •  Mahiraj Singh,National Institute of Technology (NIT), Uttarakhand<br/>
                        •  Sheeraz Kirmani,AMU<br/>
                        •  Mahendra Lalwani,RTU, Kota<br/>
                        •  Vivek Prakash Gupta,Banasthali Vidyapith<br/>
                        •  K.R.Niazi,MNIT Jaipur<br />
                        •  Arun Kumar Verma,IIT,Jammu<br />
                        •  Ajay Kumar,bits mesra<br/>
                        •  Akanksha Shukla,Sardar Vallabhbhai National Institute of Technology<br/>
                        •  Hari Om Bansal,BITS Pilani<br />
                        •  Akhil Ranjan Garg,JNVU,Jodhpur<br />
                        •  Pankaj Verma,CEA,GOI<br />
                        •  Bharat Singh Rajpurohit,IIT,Jodhpur<br />
                        •  Prof.G.R.Sinha,GSFC university,Gujarat<br />
                        •  Dr.Somitra Sanadhaya,IIT Jodhpur<br />
                        •  Basant Agarwal,Central University,Rajasthan<br />
                    </p>
                </div>
            </main>
        </>
    )
}

export default Advisory;
