import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import '../app.css';
function NavigationMenu() {
  const tabs = "Home/About Us/Committee/Call For Papers/ACTET 2023/Registration/Contact Us".split('/');
  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    // 👇️ toggle isActive state variable
    setIsActive(current => !current);
  };
  return (
    <section id="tabs" className="nav-bar nav-container">
      <div style={isActive ? { width: '100%', display: 'flex', justifyContent: 'end', position: 'relative', top: '10px', right: '18px' } : { width: '100%', display: 'flex' }}>
        <button className={isActive ? 'fas fa-times' : 'fas fa-bars'} onClick={handleClick}>
        </button>
      </div>
      <nav className={isActive ? 'nav-toggle' : 'navbar'} aria-expanded="true" role="menu" tabIndex="-1">
        <ul>
          {tabs.map((item, index) =>
            <li key={index} >
              {item === 'Committee' ? <a>Committee</a> : <Link to={item==='Call For Papers' ? 'cfp' :item.replace(' ', '-').toLowerCase() == 'home' ? '/' : item.replace(' ', '-').toLowerCase()} onClick={handleClick}>{item}</Link>}
              {item === 'Committee' && <ul className='submenu'>
                <li><Link to="advisory" onClick={handleClick}>Advisory Board</Link></li>
                <li><Link to="organizing" onClick={handleClick}>Organizing Chair</Link></li>
                <li><Link to="tpc"onClick={handleClick}>Technical Progam Committee</Link></li>
                <li><Link to="lot"onClick={handleClick}>Local Organizing Team</Link></li>
              </ul>}
            </li>
          )}
        </ul>

      </nav>
    </section>
  )
}

export default NavigationMenu;