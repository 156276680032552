import React from 'react';
import '../app.css';
import { Helmet } from "react-helmet";
function Authors() {

    return (
        <>
            <Helmet>
                <meta name="description" content='' />
                <title>ACTET: Authors</title>
                <link rel="canonical" href="https://actet2023.skit.ac.in/cfp" />
            </Helmet>
            <div className='about'>
                <div>
                    <p>
                        The International Conference on Advanced Computing Techniques in Engineering & Technology (ACTET-2025) aims to unite scholars and industry professionals to delve into the core principles of advanced scientific computing. It seeks to explore specific mechanisms and algorithms across various domains of engineering and technology. The conference serves as a platform for exchanging innovative ideas, expertise, and experiences in advanced computing techniques. The central theme of the conference is "Empowering Smart Systems with Big Data, Machine Learning, and Optimization Strategies." We welcome technical papers on a wide range of topics within the conference's scope, encompassing but not limited to:
                    </p>
                </div>
                <div>
                    <h2>TRACK 1: Foundational Concepts and Innovations in Advanced Computing</h2>
                    <p>
                        •	Parallel Computing<br />
                        •	Distributed Computing<br />
                        •	Emerging Computing Paradigms<br />
                        •	Future Directions in Computing Technologies<br />
                    </p>
                </div>
                <div>
                    <h2>Track 2 :Big Data Analytics and Machine Learning Applications in Engineering & Technology</h2>
                    <p>
                        •	Data Preprocessing and Cleaning<br />
                        •	Machine Learning Algorithms<br />
                        •	Domain-Specific Applications<br />
                        •	Ethical and Privacy Considerations
                    </p>
                </div>
                <div>
                    <h2>Track 3 :Enhancing Performance of Smart Energy Systems through Optimization</h2>
                    <p>
                        •	Application of Optimization Algorithms for Energy Systems<br />
                        •	Implementing Multi-objective Optimization Methods to improve performance of Power Systems<br />
                        •	Real-time Optimization Techniques for Smart Systems<br />
                        •	Sustainability-focused Optimization Approaches in Energy Systems
                    </p>
                </div>
                <div>
                    <h2>Track 4 :Interdisciplinary Integration of Computing Technologies for Smart Systems Design</h2>
                    <p>
                        •	Integration Architectures and Frameworks<br />
                        •	Case Studies in Smart Infrastructure<br />
                        •	Healthcare and Biomedical Applications<br />
                        •	Sustainable Urban Development
                    </p>
                </div>
                <div>
                    <h2>Instructions for Authors:</h2>
                    <p>
                        • The authors are invited to submit the Full papers of the Research/Review Papers. The papers will be peer-reviewed and only the accepted and registered papers will be considered for presentation during the conference and publication in the proceedings.<br />
                    </p>
                </div>
                <div>
                <p><b>NOTE :Acceptance of a full-length submission is strictly based on the reviewers’ comments.</b></p><br />
                    <p>All submissions need to be submitted through the CMT using the link:<a href='https://cmt3.research.microsoft.com/ACTET2025/Submission/Index' target="_blank">https://cmt3.research.microsoft.com/ACTET2025/Submission/Index</a></p>
                    <p>Atleast one of the authors of an accepted paper needs to register for the conference and the present the paper(s).</p>
                    <p><b>All Manuscripts should be prepared in the following Springer Book's Chapter Template:</b>
                        <div>
                            <a href='/assets/word.zip' download ><button className="btn"><i className="fa fa-download"></i> MSWord</button></a>
                            <a style={{ marginLeft: '1%' }} href='assets/LaTeX2e+Proceedings+Templates+download.zip' download><button className="btn"><i className="fa fa-download"></i> Latex</button></a>
                        </div>
                    </p>
                </div>
                {/* <div>
                    <h2>Important Dates:</h2>
                    <p> ● Paper Submission Date :15/05/2023<br />
                        ● Last Date of Paper Submission :<s>30/06/2023</s>&nbsp;&nbsp;25/08/2023<br />
                        ● Review Notification :<s>30/07/2023</s>&nbsp;&nbsp;12/09/2023<br />
                        ● Revised Manuscript Submission :<s>20/08/2023</s>&nbsp;&nbsp;30/09/2023<br />
                        ● Camera Ready Submission :<s>10/10/2023</s>&nbsp;&nbsp;10/11/2023
                    </p>
                </div> */}
            </div>
        </>
    )
}

export default Authors;
