import React from 'react';
import '../app.css'
import { Helmet } from "react-helmet";
function Committe() {

    return (
        <>
            <Helmet>
                <meta name="description" content='' />
                <title>ACTET: Organizing</title>
                <link rel="canonical" href="https://actet2023.skit.ac.in/organizing " />
            </Helmet>
            <main>
                <div className='about'>
                    <h2 className='marginTop'>EXECUTIVE PROGRAM CHAIR</h2>
                    <p>
                        •  Raja Ram Meel, Patron, SKIT, Jaipur<br />
                        •  Surja Ram Meel, Chairman, SKIT, Jaipur
                    </p>
                    <h2>GENERAL CHAIR</h2>
                    <p>
                        •  S.L Surana, SKIT, Jaipur<br/>
                        •  Ramesh Kumar Pachar, SKIT, Jaipur
                    </p>

                    <h2>PROGRAM CHAIR</h2>
                    <p>
                        •   R.C. Bansal , University of Sharjah,UAE<br />
                        •   Vladan B. Devedžić,University of Belgrade,Serbia<br />
                        •	Richi Nayak, Queensland University of Technology, Brisbane<br />
                        •	Basant Agarwal,Central University,Rajasthan<br />
                        •	Ankush Tandon,SKIT,Jaipur<br />
                        •	Pooja Jain, SKIT, Jaipur

                    </p>

                    <h2>ORGANIZING CHAIR</h2>
                    <p>
                        •	Md. Rizwan, DTU, Delhi<br />
                        •	Sarfaraz Nawaz, SKIT, Jaipur
                    </p>

                    <h2>PUBLICATION CHAIR</h2>
                    <p>
                        •   Akanksha Shukla, SVNIT, Surat<br />
                        •	Kapil Shukla,MNIT, Jaipur<br />
                        •	Saurabh Singh,MNIT,Jaipur<br />
                        •	Virendra Sangtani, SKIT, Jaipur<br />
                        •	Vikas Ranveer Singh Mahala, SKIT, Jaipur

                    </p>

                    <h2>Publicity Chair</h2>
                    <p>
                        •	Ajay Kumar,PEC,Chandigarh<br />
                        •	Naveen Jain, SKIT, Jaipur<br />
                    </p>

                    <h2>Organizing Secretary</h2>
                    <p>
                        •	Deepti Arela,SKIT,Jaipur<br />
                        •	Abhishek Gupta, SKIT, Jaipur<br />
                        •	Ajay Bhardwaj, SKIT, Jaipur<br />
                    </p>
                </div>
            </main>
        </>
    )
}

export default Committe;
