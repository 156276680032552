import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "screens/App";
(() => {
  ReactDOM.render(
    <BrowserRouter>
      <App />
      {/* <AboutUs /> */}
      {/* <Authors /> */}
    </BrowserRouter>,
    document.getElementById("root")
  );
})();