import React from 'react';
import '../app.css';
import { Helmet } from "react-helmet";
function Lot() {

    return (
        <>
            <Helmet>
                <meta name="description" content='' />
                <title>ACTET: LOT</title>
                <link rel="canonical" href="https://actet2023.skit.ac.in/lot" />
            </Helmet>
            <div className='about'>
                <div>
                    <h2 className='marginTop'>Local Organizing Team</h2>
                    <p>
                    •	Dr.Virendra Sangtani <br />
                    •	Dr.Dhanraj Chitara <br />
                    •	Dr.Suman Sharma <br />
                    •	Dr.Ankit Vijayvargiya <br />
                    •	Dr.Jyoti Shukla <br />
                    •	Dr.Abhishek Gupta <br />
                    •	Mr.Tarun Naruka <br />
                    •	Dr.Bharat Modi <br />
                    •	Ms.Smriti Jain <br />
                    •	Md.Yusuf Sharif <br />
                    •	Mr.Garvit Gupta <br />
                    •	Mr.Gaurav Kansal <br />
                    •	Mr.Deepak Saini <br />
                    •	Mr.Ajay Bhardwaj <br />
                    •	Mr.Avadhesh Sharma <br />
                    •	Mr.Jitendra Singh <br />
                    •	Mr.Jinendra Rahul <br />
                    •	Mr.Vivek Sharma <br />
                    •	Dr.Sanjeev Kumar <br />
                    •	Mr.Mahesh Meena 
                    </p>
                </div>
            </div>
        </>
    )
}

export default Lot;
