import React from 'react'

function Footer() {
    return (
        <div className="container">
            <div className="social-links mt-3">
                {/* <a href="https://twitter.com/" className="twitter"><i className="bx bxl-twitter"></i></a> */}
                <a href="https://www.facebook.com/profile.php?id=100089105631942&mibextid=ZbWKwL" className="facebook"><i className="bx bxl-facebook"></i></a>
                <a href="https://instagram.com/actet_2023?igshid=YmMyMTA2M2Y=" className="instagram"><i className="bx bxl-instagram"></i></a>
                <a href="https://www.linkedin.com/" className="linkedin"><i className="bx bxl-linkedin"></i></a>
            </div>
            <div className="copyright"> © ACTET 2025, All Rights Reserved. </div>
            <div className="credits">Created By <a href='https://www.linkedin.com/in/mukul-gupta-a92a93247/' target='_blank'>Mukul Gupta</a></div>
        </div>
    )
}

export default Footer;