import React from 'react';
import '../app.css'
import { Helmet } from 'react-helmet';
function Tpc() {

    return (
        <>
            <Helmet>
                <meta name="description" content='' />
                <title>ACTET: TPC</title>
                <link rel="canonical" href="https://actet2023.skit.ac.in/tpc" />
            </Helmet>
            <main>
                <div className='about'>
                    <h2 className='marginTop' style={{ marginTop: "4rem" }}>Technical Program Committee(ACTET-2023)</h2>
                    <p>
                        •    Aarti Chugh,SGT University, Gurgaon<br/>
                        •    Abhinav Bhatnagar,Birla Institute of Applied Sciences, Bhimtal<br/>
                        •    Akanksha Shukla,SVNIT Surat<br/>
                        •    Akhlaqur Rahman,Engineering Institute of Technology<br/>
                        •    Amit Gupta,GGITS JABALPUR<br/>
                        •    Amit Sharma,Career point University , kota<br/>
                        •    Anamika Yadav,National Institute of Technology, Raipur<br/>
                        •    Ankita,Pranveer Singh Institute Of Technology, Kanpur<br/>
                        •    Ankush Koli,Malaviya National Institute of Technology, Jaipur<br/>
                        •    Anubhav Pandey,Manipal Institute Of Technology,MAHE<br/>
                        •    Arvind Kumar,Government Engineering College Bikaner, Rajasthan<br/>
                        •    Ashwani  Yadav,Amity University Rajasthan, Jaipur<br/>
                        •    Awanish Mishra,Pranveer Singh Institute of Technology, Kanpur<br/>
                        •    Bhanu Tekwani,VIT mumbai<br/>
                        •    Binod Kumar,IIT Jodhpur<br/>
                        •    Dhiraj Magare,Ramrao Adik Institite Of Technology, Nerul, Navi Mumbai<br/>
                        •    Divya Rishi Shrivastava,Manipal University Jaipur<br/>
                        •    Fareed AHMAD,SND College of Engineering and Research Center, Yeola, Nashik<br/>
                        •    Ganesh Gupta,Sharda University<br/>
                        •    Gargi Khanna,NIT Hamirpur<br/>
                        •    Garima Nahar,S. S. Jain Subodh P.G. Mahila Mahavidyalaya, Rambagh, Jaipur<br/>
                        •    Gaurav Jain,Poornima College of Engineering<br/>
                        •    Gaurav Meena,Central university of Rajasthan"<br/>
                        •    H Manoj Gadiyar,SDM Institute of Technology<br/>
                        •    Habib Ur Rahman Habib,Durham University<br/>
                        •    Hossein Khorramdel,Shiraz University of Technology<br/>
                        •    Jyoti Bhonsle,Academic Researcher<br/>
                        •    Kapil Shukla,MNIT Jaipur<br/>
                        •    Karthick Prasad Gunasekaran,Amazon<br/>
                        •    Kasturi Vasudevan,Professor, EE, IIT Kanpur"<br/>
                        •    Kaviyarasi R,Sri Vidya Mandir Arts and Science College<br/>
                        •    Kevin Joshi,Respirer Living Sciences Pvt. Ltd.<br/>
                        •    Khushboo  Tripathi,Amity University Gurgaon<br/>
                        •    Kirti Pal,GBU Greater Noida<br/>
                        •    Mahendra Bhadu,Government Engineering College Bikaner<br/>
                        •    Manish Singla,Chitkara University, Rajpura<br/>
                        •    Mohit saxena,Tcs<br/>
                        •    Monika Goyal,Dayanand Sagar University<br/>
                        •    Mushtaq Ahmed,MNIT Jaipur<br/>
                        •    Nagendra Singh,National Institute of Technology, Hamirpur<br/>
                        •    Navin Paliwal,Govt. Engineering College Bikaner<br/>
                        •    Neeraj Gupta,Amity University Haryana<br/>
                        •    Neeraj Kanwar,Manipal University Jaipur<br/>
                        •    Neha Adhikari,Central Power Research Institute<br/>
                        •    Nidish Vashistha,Micron Technology Inc.<br/>
                        •    Nikita Jain,Poornima college of Engineering<br/>
                        •    Nitesh Mudgal,Poornima College of Engineering, Jaipur<br/>
                        •    Parameswaran Ramesh,Madras Institute of Technology<br/>
                        •    Pardha Saradhi J,Bapatla Engineering College<br/>
                        •    Patel Pravinbhai,Energy Systems Engineering<br/>
                        •    Praful Nandankar,Government College of Engineering, Nagpur<br/>
                        •    Prashant Kumar,J C Bose University of Science & Technology, YMCA Faridbad<br/>
                        •    Pravin Sonwane,Poornima College of Engineering<br/>
                        •    Preeti Narooka,Manipal University Jaipur<br/>
                        •    Prerak Bhardwaj,Jaipur Engineering College and Research Centre<br/>
                        •    Priyanka  Makkar,Amity University Haryana<br/>
                        •    Radhakrishna Bhat,Manipal Institute of Technology, Manipal Academy of Higher Education<br/>
                        •    Rahul Soni,Pandit Deendayal Energy University<br/>
                        •    Rajat Verma,Pranveer Singh Institute of Technology, Kanpur, Uttar Pradesh, India<br/>
                        •    Rakesh Choudhary,Swami Keshvanand Institute of Technology, Management & Gramothan, Jaipur<br/>
                        •    Rashmi Gupta,Amity University Haryana<br/>
                        •    Ravita Lamba,MNIT, Jaipur<br/>
                        •    Rudranarayan Senapati,Kalinga Institute Of Industrial Technology<br/>
                        •    Sandeep Vyas,JECRC Jaipur, India<br/>
                        •    Sangeeta Singh,Vardhaman College of Engineering<br/>
                        •    Satish Kumar Paturi,IIT DELHI<br/>
                        •    Satyasundara Mahapatra,Pranveer Singh Institue of Technology<br/>
                        •    Satyendra Singh,Bhartiya Skill Development University Jaipur<br/>
                        •    Shashi Sharma,IIIT Ranchi<br/>
                        •    Siba Kumar Patro,Indian Institute of Technology Roorkee<br/>
                        •    Sohit  Agarwal,Suresh Gyan Vihar University,Jaipur<br/>
                        •    Sonam Rewari,Delhi Technological University<br/>
                        •    Soumesh Chatterjee,Institute of Technology, Nirma University<br/>
                        •    Sourabh Sahu,GGITS, jabalpur<br/>
                        •    Sreenu Sreekumar,National Institute Of Technology Silchar<br/>
                        •    Srinivasulu Raju S,VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE<br/>
                        •    Subhajit Roy,Budge Budge Institute of Technology<br/>
                        •    Sujoy Das,Tripura Institute of Technology Narsingarh<br/>
                        •    Sunil ,Jamia Millia Islamia (A Central University), New Delhi<br/>
                        •    Surbhi Sharma,Manipal University Jaipur<br/>
                        •    Sweta Tripathi,Amity University, Haryana<br/>
                        •    Vedik Basetti,SR University, Warangal<br/>
                        •    Vijay prakash Sharma,Manipal University Jaipur<br/>
                        •    Virupakshappa Patil,Sharnbasva University Kalaburagi, Karnataka, INDIA<br/>
                        •    Vivek Kumar,PSIT KANPUR<br/>
                        •    Vivek Prakash,Banasthali Vidyapith<br/>
                    </p>
                </div>
            </main>
        </>
    )
}

export default Tpc;



