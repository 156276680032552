import React from 'react'
import Slider from 'react-slick'

function AppSlider() {
    const settings = {
        dots: true,
        infinite: true,
        //adaptiveHeight: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };

    return (
        <Slider {...settings}>
            <div>
                <img src='./images/Bikaner.jpg' alt='Hawa Mahal' style={{
                    filter:"brightness(50%)"
                }} />
                <div className='slide-text'><i>ACTET 2025</i></div>

            </div>
            <div>
                <img src='./images/hawa-mahal.jpg' alt='Hawa Mahal' style={{
                    filter:"brightness(50%)"
                }} />
                <div className='slide-text'><i>ACTET 2025</i></div>

            </div>
            <div>
                <img src='./images/skit45.jpg' alt='Hawa Mahal'style={{
                    filter:"brightness(50%)"
                }}  />
                <div className='slide-text'><i>ACTET 2025</i></div>
            </div>
            {/* <div>
                <img src='./images/auditorium.jpg' alt='Hawa Mahal' />
                <div className='slide-text'>ACTET 2023</div>
            </div> */}
            <div>
                <img src='./images/auditorium1.jpg' alt='Hawa Mahal'style={{
                    filter:"brightness(50%)"
                }}  />
                <div className='slide-text'><i>ACTET 2025</i></div>
            </div>
        </Slider>
    )
}

export default AppSlider;