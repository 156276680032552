import React from 'react';
import '../app.css';
import { Helmet } from "react-helmet";
function AboutUs() {

    return (
        <>
            <Helmet>
                <meta name="description" content='' />
                <title>ACTET: About US</title>
                <link rel="canonical" href="https://actet2023.skit.ac.in/about-us" />
            </Helmet>
            <div className='about'>
                <div className='full'>
                    <div style={{ flex: 1, order: 2 }}>
                        <img src='images/skit45.jpg' className='aboutUsimg1' />
                    </div>
                    <div style={{ flex: 1 }}>
                        <h2 className='header' style={{ width: "96%" }}>About SKIT</h2>
                        <p>Swami Keshvanand Institute of Technology, Management & Gramothan (SKIT) draws inspiration from
                            the visionary teachings of Swami Keshvanand and was established in 2000 by Technocrats and
                            Managers Society for Advanced Learning.
                        </p>
                        <p>Over the years, SKIT has emerged as a beacon of academic
                            excellence in Northern India. Affiliated with Rajasthan Technical University, Kota, SKIT offers a
                            diverse range of Doctorate, Postgraduate, and Graduate courses in Engineering and Management.</p>
                        <p>
                            The institute's commitment to quality education is reflected in its accreditation by NAAC with an
                            impressive A++ Grade (3.67 CGPA on a 4-point scale).</p>
                        <p> Notably, SKIT stands out as one of India's
                            premier institutions, being the sole affiliated technical institute in Rajasthan to achieve the prestigious
                            A++ grade from the National Assessment and Accreditation Council (NAAC).
                        </p>
                        <p>Continuously ranked as the top institution by RTU, Kota for the past seven years, SKIT has further solidified its status as a leader in education.</p>
                        <p>In a significant milestone, SKIT has become Rajasthan's first autonomous college affiliated with RTU,
                            signaling its dedication to academic autonomy and innovation in education.</p>
                    </div>

                </div>


                <div className='full'>
                    <div style={{ flex: 1 }}>
                        <img src='images/lab.jpg' className='aboutUsimg1' />
                    </div>
                    <div style={{ flex: 1, order: 2 }}>
                        <h2 style={{ width: "96%" }}>About Department of Electrical Engineering</h2>
                        <p>The department is a dynamic and growing community of scholars with an emphasis on research-oriented teaching and is active in most of the dimensions of electrical engineering. Apart from academics, R&D, consultancy, continuing education programs and administrative responsibilities, the faculty participates in many interdisciplinary activities for the perpetual enhancement of teaching skills. The department faculty regularly contributes to conferences and journals at The National and International levels for the sake of upgrading knowledge in the respective fields.</p>
                        <p>The graduates of the department venture out for a wide range of organizations including reputed MNC's (Adani Power, TCS, Infosys Ltd. C&G, etc.), PSU's (PGCIL, BHEL, BARC, etc.), Armed Forces and Indian Engineering Services (IES). The students are also successfully seeking admissions in reputable universities all around the world for further studies and global exposure.</p>
                    </div>
                </div>

                <div className='full'>
                    <div style={{ flex: 1, order: 2 }}>
                        <img src='images/jaipur.jpg' className='aboutUsimg1' />
                    </div>
                    <div style={{ flex: 1 }}>
                        <h2 style={{ width: "96%" }}>About Pink City, Jaipur</h2>
                        <p>Jaipur is a planned medieval city founded in 1727 as the capital of erstwhile Dhoondhar kingdom by King Sawai Jai Singh and planned by the Architect Vidyadhar Bhattacharya on the principle of Vastushashtra. It is also the capital of the state of Rajasthan, located at around 265 km from the national capital Delhi, well connected by the air, rail and road transportation network to other parts of the country. It is famous as Pink City with attractive places such as magnificent palaces and forts of Amber, Jaigarh, Nahargarh, City place, besides Hawa Mahal, Jal mahal, Jantar Mantar etc. situated in the heart of the city.This city has been trendy amongst the visitors since earlier times, due to the availability of several famous hotels. One of which is the Raj Vilas which is known widely due to its beautiful architecture. These palaces in ancient times belonged to the kings and their families. Therefore, all of these hotels have a bit of royalty in them.he capital of Rajasthan, i.e. Jaipur, is famous and is considered one of the best cities under the top ten throughout the country. Besides, it is the biggest city across the entire state of Rajasthan. There are several sightseeing locations present in Jaipur that are popular amongst the visitors.</p>
                        <p>Some of the topmost sites available in Jaipur are Jal Mahal, Amber fort, Albert hall museum, Elephant safari, Jantar Mantar, Hawa Mahal, Nahargarh fort, City Palace, etc. Thus, the pink city, Jaipur is filled with attractive monuments, royal palaces, alluring cultures, and so on. Also, the development of Jaipur is drastically increasing.One of the most important and topmost manufactures and exporters of camel leather, textile, handicraft, quilts, brass enamels, and so on. This city is widely famous for its art and culture that has gradually been combined with newer technologies and now lead to increased growth in the rate of export. Thus, the importance of Jaipur as a tourist location is growing exponentially. Also, the development of Jaipur is drastically increasing.</p>
                    </div>

                </div>
            </div>
        </>

    )
}

export default AboutUs;
